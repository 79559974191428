
import deliceDesDoudou from '../images/delice/IMG_60982.jpg';
import bizzon from '../images/Bizon/IMacWorkSpace2_Mockups.png';
import ra from '../images/Revesargentes/RA_Laptop_Mockup2.jpg';
import extaz from '../images/Extaz/extazcard.png';
import rear from '../images/realisation/7e782018044163.562c2dd0f3477.jpg';
import b4s from '../images/B4S/iPhoneX_Mockups.png';
import msww from '../images/msww/msww.png';
import moise from '../images/Moise/moise.png';
import joe from '../images/Joevent/Sans titre-22.jpg';


const cards = [
    { id: 5, title: 'Rêves Argentés', description: '', color: '#FF69B4', image: ra},

   // { id: 2, title: 'Card 2', description: 'Description for card 2', type:"talk"},
    { id: 2, title: 'Del ile s des doudou', description: '', color: '#1f84f8',image: deliceDesDoudou},
    { id: 3, title: 'B4Star', description: '', color: '#5c00fa', image: b4s },
    { id: 4, title: 'Extaz Cafée', description: '', color: '#4682B4', image:extaz},
    { id: 8, title: 'BIZZON', description: '', color: '#0051ff', image:bizzon},

    { id: 6, title: 'Samovard', description: '', color: '#8A2BE2', image: rear },
    { id: 14, title: 'Jo event', description: '', color: 'rgba(59,18,239,0.7)', image: joe},
    { id: 11, title: 'Papa Moïse', description: '', color: '#ce4d08', image: moise },
    { id: 1, title: 'Ms WoodWork', description: '', color: '#5F9EA0',image: msww},
    //{ id: 13, title: 'WWW', description: '', color: '#14addc' },
    { id: 15, title: '•••', description: '', color: 'rgba(87,20,220,0.43)', type:"talk" },

];
export default cards;
