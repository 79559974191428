import React from 'react';
import '../style/CardsGrid.css';
import { Link } from 'react-router-dom';
import cards from '../datas/projetsData';


const CardsGrid = () => {
    return (
        <div className="grid-container">
            {cards.map((card) => (
                <Link
                    key={card.id}  // Gardez ceci ici pour l'élément Link
                    style={{ backgroundColor: card.color }}
                    to={`/details/${card.id}`}
                    className={`grid-item active 
                    ${card.type === "talk" ? 'grid-itemtalk' : ''}
                    ${card.id % 4 === 1 ? 'large' : ''} 
                    ${card.id % 2 === 0 ? 'haute' : ''}`} >

                    <div className={` ${card.type === 'talk' ? 'cardTextVisible' : 'cardText'}`}>
                        <h3>{card.title}</h3>
                        <p>{card.description}</p>
                    </div>
                    {card.image && (
                        <img src={card.image} alt={card.title} className="grid-image" />
                    )}

                </Link>
            ))}
        </div>
    );
};

export default CardsGrid;
