import React from 'react';

const About = () => {
    return (
        <div className="fullContainerWidth">

            <div className="dflex">

            <div className="asider">
               <h1>À propos</h1>
            </div>
                <div className="containerp">

                    <div className="gallery-grid">

                        <div className='grid-item large'>
                            <p>Chez To Create, nous croyons en une chose simple mais puissante : chaque idée mérite de prendre vie.
                                Nous sommes là pour accompagner les créateurs, les visionnaires, et les entreprises à transformer leurs concepts en designs uniques, en interfaces intuitives, et en solutions fonctionnelles.

                                Avec une écoute attentive et un savoir-faire multidisciplinaire, nous donnons du sens et de l’âme à vos projets. Que ce soit pour un site web élégant, un objet au design réfléchi, ou une expérience utilisateur mémorable, nous fusionnons créativité et innovation pour atteindre l’essentiel : connecter l’humain à vos réalisations.

                                Pour nous, créer, c’est plus qu’une mission. C’est une démarche qui éveille, élève et inspire.
                                Alors, ensemble, osons concevoir un monde où chaque création raconte une histoire et laisse une empreinte.
                            </p>
                        </div>
                        <div className='grid-item haute'>
                            <img alt="not now" src="https://i.pinimg.com/736x/f4/43/99/f44399e8a26bb83d6e35a689d7be619e.jpg"  className="grid-image" />
                        </div>
                        <div  className='grid-item large' >

                            <h1>
                                To Create
                            </h1>
                            <h4>Donner vie à vos idées, avec sens et émotion.
                            </h4>
                        </div>

                        <div key='1' className='grid-item large' >

                        </div>

                    </div>

                </div>

            </div>

        </div>
    );
};

export default About;
