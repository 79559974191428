import React from 'react';
import '../style/Dropdown.css';

const DropdownMenusContent = ({ CustomComponent }) => {
    return (
        <div className="dropdownMenusContent-list">
            {CustomComponent && <CustomComponent />}
        </div>
    );
};

export default DropdownMenusContent
