import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import DropdownMenusContent from "./DropdownMenusContent";
import About from "../screen/About";
import Contact from "../screen/Contact";

const NavLinks = [
    { id: 1, title: "About", elementView: About },
    { id: 2, title: "Contact", elementView: Contact },
];
const Header = () => {
    const [openMenuId, setOpenMenuId] = useState(null);
    const [isShrunk, setIsShrunk] = useState(false);
    const location = useLocation(); // Récupère la route actuelle

    const handleMenuClick = (id) => {
        setOpenMenuId((prevId) => {
            if (id === 0) {
                return null;
            }
            return prevId === id ? null : id;
        });
    };

    useEffect(() => {
        // Empêcher le défilement lorsque le menu est ouvert
        document.body.style.overflow = openMenuId ? "hidden" : "";

        const handleScroll = () => {
            setIsShrunk(window.scrollY > 5);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            document.body.style.overflow = "";
            window.removeEventListener("scroll", handleScroll);
        };
    }, [openMenuId]);

    // Vérifie si la route actuelle n'est pas "/"
    const isNotHome = location.pathname !== "/";

    return (
        <header className={`App-header ${isShrunk ? "shrink" : ""} ${openMenuId === null || openMenuId === 0 ? "headerFilter" : ""}`}>
            <div className="header-title">
                <Link to="/" onClick={() => handleMenuClick(0)}>
                    <h1
                        className={`nav-link log linkHero ${
                            isShrunk || openMenuId >= 1 || isNotHome ? "shrink" : ""
                        }`}
                    >
                        ToCreate
                    </h1>
                </Link>
            </div>

            <nav className="nav">
                {NavLinks.map((item) => (
                    <div key={item.id} className="nav-item">
                        <button
                            className="nav-link"
                            onClick={() => handleMenuClick(item.id)}
                            aria-expanded={openMenuId === item.id}
                        >
                            {item.title}
                        </button>
                    </div>
                ))}
            </nav>

            {openMenuId && (
                <div className="dropdown-menu">
                    {NavLinks.map(
                        (item) =>
                            openMenuId === item.id && (
                                <DropdownMenusContent
                                    key={item.id}
                                    CustomComponent={item.elementView}
                                />
                            )
                    )}
                </div>
            )}
        </header>
    );
};

export default Header;
