import React from 'react';
import { useParams } from 'react-router-dom';
import PROJECTDETAILS from '../datas/projectDetails'
import InfoSection from "../components/InfoSection";


const DetailsPage = () => {
    const { id } = useParams();
    const card = PROJECTDETAILS[id];

    if (!card) {
        return  <div className="containerVide">
            <h2>Card not found!</h2>
        </div>;
    }

    return (
        <div className="containerVide ">

            <section className="hero-section">

            </section>


                <InfoSection
                    title={card.title}
                    description={card.description}
                    credits={card.credits}
                    images={card.images}
                />
                <InfoSection
                    title={card.title2}
                    description={card.description2}
                    credits={card.credits2}
                    images={card.images2}
                />
            <InfoSection
                title={card.title3}
                description={card.description3}
                credits={card.credits3}
                images={card.images3}
            />

        </div>
    );
};

export default DetailsPage;
