import React, { useState } from 'react';
import '../style/Dropdown.css';
const Dropdown = ({ label, items }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="dropdown">
            <button
                className="dropdown-toggle"
                onClick={() => setIsOpen((prev) => !prev)}
            >
                {isOpen ? 'Hide Credits' : label}
            </button>

            <ul className={`dropdown-list ${isOpen ? 'open' : ''}`}>
                {items.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
        </div>
    );
};

export default Dropdown;
