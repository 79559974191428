import React, { useState, useEffect, useRef } from "react";
import '../style/InteractiveBubble.css';

const ScrollAnimatedDiv = () => {
    const [isShrunk, setIsShrunk] = useState(false);
    const containerRef = useRef(null);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        // Fonction pour gérer le scroll
        const handleScroll = () => {
            setIsShrunk(window.scrollY > 5);
        };

        // Fonction de suivi de la souris
        const handleMouseMove = (event) => {
            const container = containerRef.current;
            if (!container) return;

            // Calculer la position relative du curseur par rapport au conteneur
            const { left, top } = container.getBoundingClientRect();
            const x = event.clientX - left;
            const y = event.clientY - top;

            // Mettre à jour la position de la bulle
            setPosition({ x, y });
        };

        // Ajouter les événements "scroll" et "mousemove"
        window.addEventListener("scroll", handleScroll);
        const container = containerRef.current;
        if (container) {
            container.addEventListener("mousemove", handleMouseMove);
        }

        // Nettoyer les événements au démontage du composant
        return () => {
            window.removeEventListener("scroll", handleScroll);
            if (container) {
                container.removeEventListener("mousemove", handleMouseMove);
            }
        };
    }, []); // Le tableau de dépendances est vide pour que l'effet ne soit exécuté qu'une fois

    return (
        <div className="scrollable-content">
            <div className="gradient-bg" ref={containerRef}>
                <div className="g1"> </div>
                <div className="g2"></div>
                <div className="g3"></div>
                <div className="g4"></div>
                <div className="g5"></div>
                <div
                    className="interactive"
                    style={{
                        left: `${position.x - 50}px`, // Décalage pour centrer la bulle
                        top: `${position.y - 50}px`
                    }}
                ></div>
            </div>
            <div className={`animated-div ${isShrunk ? "shrink" : ""}`}>
                <h1>ToCreate</h1>
            </div>
        </div>
    );
};

export default ScrollAnimatedDiv;
