
import image1 from '../images/delice/Les-délilces.jpg';
import image2 from '../images/delice/IMG_6124.JPG';
import image3 from '../images/delice/IMG_6179.JPG';
import image4 from '../images/delice/Delilce2.png';

import extazHero from '../images/Extaz/A49.jpg';
import extazImg1 from '../images/Extaz/Extaz_Brand_Mockup_2.jpg';
import extazImg2 from '../images/Extaz/cup.jpg';
import extazImg3 from '../images/Extaz/Coffee_Brand_Mockup_4.jpg';

import raHero from '../images/Revesargentes/HeRO.png';
import raImg1 from '../images/Revesargentes/RA_Stationery_Mockup_1.jpg';
import raImg2 from '../images/Revesargentes/Sans-nom-4.gif';
import raImg3 from '../images/Revesargentes/Ra_Stationery_Mockup_2.jpg';
import raImg4 from '../images/Revesargentes/RA_Laptop_Mockup.jpg';
import raImg5 from '../images/Revesargentes/picto.png';

import samoHero from '../images/realisation/e1bcd018044163.562c2dd14c0c9.jpg';
import samoImg1 from '../images/realisation/c1df7b18044163.562c2dd0c3ea1.jpg';
import samoImg2 from '../images/realisation/7e782018044163.562c2dd0f3477 2.jpg';
import samoImg3 from '../images/realisation/1648e118044163.562c2dd1ba64e.jpg';

import bizzonHero from '../images/Bizon/HeRO.png';
import bizzonImg1 from '../images/Bizon/Mokebizoniphone.png';
import bizzonImg2 from '../images/Bizon/PSD 1.jpg';
import bizzonImg3 from '../images/Bizon/imacbizon.png';

import mswwImg1 from '../images/msww/MSW MockUp – Wood.jpg';
import mswwImg2 from '../images/msww/Group 1.jpg';
import mswwImg3 from '../images/msww/Wood Engraved Logo Mock-Up.jpg';

import moiseImg1 from '../images/Moise/4393d1195940953.6616cf800b05f.jpeg';
import moiseImg2 from '../images/Moise/packaging.jpg';
import moiseImg3 from '../images/Moise/index.jpg';





const PROJECTDETAILS = {
    2: {
        id: 1,
        title: 'Del ile s des doudou',
        description: 'Une invitation aux saveurs des Antilles\n' +
            '\n' +
            'Les Déli’le’s des Doudous est bien plus qu’une marque, c’est une célébration vivante de la richesse culinaire antillaise. À travers une gamme de boissons exotiques et de mets traditionnels, nous avons imaginé un univers qui perpétue les traditions tout en les partageant avec le monde.\n' +
            '\n' +
            'Une identité inspirée par les Antilles\n' +
            'Pour ancrer la marque dans son héritage, nous avons puisé dans les couleurs éclatantes des îles. Des tons chaleureux d’orange et de jaune qui évoquent le soleil, les épices et la générosité de la culture locale.\n' +
            '\n' +
            'Une typographie dansante et authentique\n' +
            'apporte une touche vivante et festive. Ses courbes reflètent le rythme et la joie de vivre des Antilles, capturant l’essence de la culture.\n' +
            '\n',

        description2: 'Les Déli’le’s des Doudous, c’est aussi un voyage sensoriel. Chaque produit raconte une histoire, celle d’un savoir-faire transmis de génération en génération, des recettes qui réchauffent le cœur et une invitation à découvrir la convivialité antillaise.\n' +
            '\n' +
            'À travers cette identité, Les Déli’le’s des Doudous vous transporte sous les palmiers, dans un monde où les saveurs et les traditions se rencontrent pour créer une expérience unique et inoubliable. Bienvenue dans notre univers.',
        color: '#FFD700',
        image: image2,
        credits: ['Credit 1', 'Credit 2', 'Credit 3'], // Crédits
        images: [
            { src:image3, fullWidth: false },
            { src:image1, fullWidth: false },
            { src:image4, fullWidth: true },
        ],
    },

    8: {

        id: 2,
        title2: 'Bizon – Application pour syndics et copropriétaires',
        description2: 'Bizon est une application innovante développée pour moderniser la gestion des copropriétés et offrir une solution centralisée aux syndics, copropriétaires et propriétaires multi-biens. Cette plateforme allie efficacité, transparence et simplicité, répondant aux besoins d’un secteur souvent perçu comme complexe et bureaucratique. Avec Bizon, le syndic gagne du temps en automatisant les processus et en améliorant la communication avec les copropriétaires. De leur côté, les propriétaires bénéficient d’une vision claire et centralisée de leurs biens, qu’ils en possèdent un ou plusieurs. Bizon se positionne ainsi comme un outil indispensable pour une gestion de copropriété transparente, collaborative et moderne.',
        description3: 'Avec Bizon, le syndic gagne du temps en automatisant les processus et en améliorant la communication avec les copropriétaires. De leur côté, les propriétaires bénéficient d’une vision claire et centralisée de leurs biens, qu’ils en possèdent un ou plusieurs. Bizon se positionne ainsi comme un outil indispensable pour une gestion de copropriété transparente, collaborative et moderne.',

        color: '#FFD700',
        image: bizzonHero,
        credits2: ['Credit 1', 'Credit 2', 'Credit 3'], // Crédits
        images: [
            { src:bizzonImg1, fullWidth: false },
            { src:bizzonImg2, fullWidth: false },
            { src:bizzonImg3, fullWidth: true },
        ],
    },



    3: {
        id: 3,
        title: 'B4Star – L’accélérateur de votre influence',
        description: 'Pour B4Star, une plateforme révolutionnaire dans l’univers de l’influence digitale, j’ai travaillé sur la conception globale du design et des interfaces, en mettant l’accent sur une expérience utilisateur fluide, intuitive et engageante. Le projet vise à réinventer la relation entre marques et consommateurs en valorisant des interactions authentiques et impactantes.',
        description2:'B4Star repose sur une mission claire : recentrer l’influence autour des besoins réels et des valeurs des consommateurs. L’interface et le design traduisent cette ambition par des outils collaboratifs et des visuels inspirants qui renforcent la confiance entre les marques et leur audience.',
        description3: 'Héritage et promesse dans le design\n' +
            '\n' +
            'Chaque pixel, chaque élément visuel est pensé pour refléter l’héritage de B4Star : donner du sens à la consommation et valoriser les voix des consommateurs. Le design permet une communication rapide, pertinente et surtout authentique, plaçant les utilisateurs au cœur de l’influence.\n' +
            '\n' +
            'Avec B4Star, l’influence devient un outil puissant, transparent et équitable pour construire un avenir où marques et consommateurs co-créent des relations de confiance.',


        color: '#FF7F50',
        image: 'https://via.placeholder.com/800x400',
        credits: ['Credit A', 'Credit B'],
        gallery: ['https://kalunea.com/cdn/shop/files/S8a2ac00ed1d34ce5b5c35a4c477abe9ej_0141df81-b539-4bf7-a8c4-61eeb009a3d2.webp?v=1699036109&width=1000'],
    },

    4: {
        id: 4,
        title: 'Extase Café',
        description: 'Le logo raconte une histoire : celle d’un moment d’évasion et de réconfort, où chaque tasse de café devient une parenthèse de plaisir intense. La silhouette féminine symbolise l’élégance et l’émotion, tandis que les éléments naturels, comme les baies et les feuilles de café, évoquent la richesse et l’authenticité des origines du produit. Le design joue sur des lignes fines et délicates, offrant un équilibre entre détails organiques et une esthétique épurée. La femme drapée, enveloppée dans un mouvement fluide, renforce l’idée de chaleur et de confort.',
        description2: 'Cette identité visuelle incarne une vision : offrir à chaque client bien plus qu’une tasse de café, une véritable expérience sensorielle et émotionnelle, enracinée dans la passion du produit et l’amour de la nature.',

        color: '#FF5733',
        image: extazHero,
        credits: ['Design by Alice', 'Photography by Bob', 'Development by Carol'],
        images: [
            { src:extazImg1, fullWidth: false },
            { src:extazImg2, fullWidth: false },
            { src:extazImg3, fullWidth: true },
        ],
    },
    5: {
        id: 5,
        title: 'Identité visuelle et site web de l’association Rêves Argentés',
        description: 'Rêves Argentés est une association dédiée aux seniors, visant à enrichir leur quotidien à travers des services, des activités et un réseau solidaire. Avec son siège en Martinique, l’association souhaitait une identité visuelle et un site web modernes, chaleureux et accessibles, reflétant ses valeurs de proximité et d’ouverture.',
        description2: 'Les lettres RA, conçues sous la forme d’une signature manuscrite, reflète l\'approche humaine, personnalisée et authentique. Ce style rappelle l’importance des liens et des histoires partagées par les seniors.\n' +
            'Trapèze ouvert : L’inscription est encadrée dans un trapèze représentant une porte ouverte, symbole d’accueil, de nouvelles opportunités et de dynamisme.',
        description3: 'L’identité visuelle et le site web de Rêves Argentés incarnent la mission de l’association : offrir une porte ouverte sur un quotidien enrichissant et solidaire pour les seniors. Ce projet marque une étape importante dans la visibilité et l’impact de l’association, tant en Martinique qu’au-delà.',
        color: '#33FFBD',
        image: raHero,
        credits: ['Mission :\n' +
        'Création de l’identité visuelle, comprenant un logo inspiré d’une signature manuscrite et un trapèze représentant une porte ouverte.\n' +
        'Conception et développement d’un site web moderne, intuitif et accessible, optimisé pour les seniors.', 'Réalisation :\n' +
        'Design graphique du logo et définition de la charte graphique.\n' +
        'Développement du site sur WordPress, avec des modules personnalisés et un hébergement sur le cloud.\n' +
        'Gestion complète du projet, de la conceptualisation à la mise en ligne.', 'design by Loic'],
        images: [
            { src:raImg1, fullWidth: false },
            { src:raImg2, fullWidth: false },
            { src:raImg3, fullWidth: true },
            { src:raImg5, fullWidth: false},
        ],
        images2: [
            { src:raImg4, fullWidth: false },
            { src:raImg2, fullWidth: false },
            { src:raImg3, fullWidth: true },
        ],
    },

    6: {
        id: 6,
        title: 'Identité visuelle pour Le Samovar, école de clown',
        description: 'Pour Le Samovar, une école de clown reconnue pour son approche artistique unique et ludique, j’ai conçu une identité visuelle moderne et mémorable, basée sur un design filaire. L’objectif était de capturer l’essence poétique et joyeuse du clown tout en apportant une touche de minimalisme contemporain. Le choix du design filaire s’est imposé comme une évidence : il traduit avec légèreté et fluidité les mouvements gracieux et expressifs du clown. Chaque élément graphique, des lignes sinueuses aux formes esquissées, évoque la spontanéité, le jeu et l’émotion – des valeurs centrales de l’art clownesque.',
        description2: 'Les Déli’le’s des Doudous, c’est aussi un voyage sensoriel. Chaque produit raconte une histoire, celle d’un savoir-faire transmis de génération en génération, des recettes qui réchauffent le cœur et une invitation à découvrir la convivialité antillaise.\n' +
            '\n' +
            'À travers cette identité, Les Déli’le’s des Doudous vous transporte sous les palmiers, dans un monde où les saveurs et les traditions se rencontrent pour créer une expérience unique et inoubliable. Bienvenue dans notre univers.',

        color: '#6A0DAD',
        image: samoHero,
        credits: ['Designed by Grace', 'Coded by Henry', 'Tested by Isla'],
        images: [
            { src:samoImg1, fullWidth: false },
            { src:samoImg2, fullWidth: false },
            { src:samoImg3, fullWidth: true },
        ],

    },
    1: {
        id: 7,
        title2: ' Identité visuelle pour MSWW, ébéniste spécialisé dans le sur-mesure',
        description2: 'Le logo représente un arbre stylisé, formé par l’assemblage de rectangles pleins et en contour. Chaque rectangle symbolise un élément de mobilier, comme des étagères ou des portes, rappelant les créations cubiques et modulaires de l’atelier. Cette structure graphique traduit la maîtrise technique et la vision contemporaine de MSWW tout en faisant écho à la matière première au cœur de leur métier : le bois.',
        description3: 'Ce logo et l’identité visuelle dans son ensemble traduisent l’équilibre parfait entre tradition artisanale et design contemporain, tout en célébrant la beauté intemporelle du bois et le travail sur-mesure. MSWW est ainsi positionné comme un acteur incontournable pour des intérieurs uniques et élégants.',

        color: '#008080',
        image: mswwImg3,
        credits2: ['VR Lead: Jack', '3D Artist: Kate', 'Producer: Liam'],
        images: [
            { src:mswwImg1, fullWidth: false },
            { src:mswwImg2, fullWidth: false },

        ],
    },
    0: {
        id: 8,
        title: 'Project 8',
        description: 'Reviving classic aesthetics for modern audiences.',
        color: '#FF4500',
        image: image2,
        credits: ['Art Direction: Mia', 'Photography: Noah', 'Styling: Olivia'],
        images: [
            { src:image3, fullWidth: false },
            { src:image1, fullWidth: false },
            { src:image4, fullWidth: true },
        ],
    },
    9: {
        id: 9,
        title: 'Project 9',
        description: 'Celebrating diversity through visual storytelling.',
        color: '#FFD700',
        image: image2,
        credits: ['Concept: Peter', 'Illustrations: Quinn', 'Copywriting: Riley'],
        images: [
            { src:image3, fullWidth: false },
            { src:image1, fullWidth: false },
            { src:image4, fullWidth: true },
        ],
    },
    10: {
        id: 10,
        title: 'Project 10',
        description: 'A bold exploration of typography in motion.',
        color: '#32CD32',
        image: image2,
        credits: ['Animation: Sophia', 'Typography: Thomas', 'Sound Design: Ursula'],
        images: [
            { src:image3, fullWidth: false },
            { src:image1, fullWidth: false },
            { src:image4, fullWidth: true },
        ],
    },
    11: {
        id: 11,
        title: 'Project 11',
        description: 'Interactive art for the digital generation.',
        color: '#4682B4',
        image: image2,
        credits: ['Designer: Victor', 'Engineer: Wendy', 'Curator: Xavier'],
        images: [
            { src:moiseImg1, fullWidth: false },
            { src:moiseImg2, fullWidth: false },
            { src:moiseImg3, fullWidth: true },
        ],
    },
    12: {
        id: 12,
        title: 'Project 12',
        description: 'Blending nature and technology in harmony.',
        color: '#9ACD32',
        image: image2,
        credits: ['Environment Design: Yasmin', '3D Models: Zach', 'Composer: Aaron'],
        images: [
            { src:image3, fullWidth: false },
            { src:image1, fullWidth: false },
            { src:image4, fullWidth: true },
        ],
    },
    13: {
        id: 13,
        title: 'Project 13',
        description: 'A sensory experience through immersive media.',
        color: '#800000',
        image: image2,
        credits: ['Media Artist: Bella', 'Interactive Dev: Caleb', 'Editor: Danielle'],
        images: [
            { src:image3, fullWidth: false },
            { src:image1, fullWidth: false },
            { src:image4, fullWidth: true },
        ],
    },
    14: {
        id: 14,
        title: 'Project 14',
        description: 'Showcasing the power of collaboration in design.',
        color: '#FF69B4',
        image: image2,
        credits: ['Team Lead: Ethan', 'Graphic Design: Fiona', 'Research: Gabriel'],
        images: [
            { src:image3, fullWidth: false },
            { src:image1, fullWidth: false },
            { src:image4, fullWidth: true },
        ],
    },
    15: {
        id: 15,
        title: 'Project 15',
        description: 'Reimagining urban spaces through creative thinking.',
        color: '#00CED1',
        image: image2,
        credits: ['Urban Planner: Hannah', 'Architect: Isaac', 'Photographer: Julia'],
        images: [
            { src:image3, fullWidth: false },
            { src:image1, fullWidth: false },
            { src:image4, fullWidth: true },
        ],
    },


};

export default PROJECTDETAILS
