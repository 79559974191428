import React from 'react';


const Footer = () => {
    return (
        <div className="Application-footer">

                    <div className="containerp">
                        <h1>To Create</h1>
                        <p>
                            <a href="/">Nous aidons créateurs et entreprises à transformer leurs idées en designs uniques et solutions impactantes.</a>
                        </p>
                        <p>Créer, c’est inspirer, élever et marquer les esprits.</p>
                        <h3>
                            Ensemble, donnons vie à des histoires qui laissent une empreinte.
                        </h3>
                    </div>

        </div>
    );
};

export default Footer;
