import React from 'react';

const Contact = () => {
    return (
        <div className="fullContainerWidth">
            <div className="dflex">

                <div className="asider">
                    <h1>Contactez-nous</h1>

                </div>
                <div className="containerp">

                    <div className="gallery-grid">

                        <div className='grid-item large'>
                            <p>Chez To Create, nous croyons que chaque échange est une opportunité de donner vie à vos idées. Vous avez un projet en tête, une question ou simplement envie d'en savoir plus sur nos services ? Nous sommes à votre écoute.
                            </p>
                        </div>
                        <div className='grid-item haute'>
                            <img alt={' '} src="https://i.pinimg.com/736x/f4/43/99/f44399e8a26bb83d6e35a689d7be619e.jpg"  className="grid-image" />
                        </div>
                        <div  className='grid-item large' >

                            <h3>
                                Comment nous joindre ?
                            </h3>
                            <p>
                                📧 Email : contact@tocreate.com
                            </p>
                            <p>
                                📞 Téléphone : +33 1 23 45 67 89
                            </p>
                        </div>



                        <div key='1' className='grid-item large' >

                        </div>


                    </div>

                </div>


            </div>
        </div>
    );
};

export default Contact;
