import './App.css';
import Header from "./components/Header";
import Home from "./screen/Home"
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import About from "./screen/About";
import Contact from "./screen/Contact";
import DetailsPage from "./screen/DetailsPage";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">

        <Router>

            <Header />
            <div>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="/details/:id" element={<DetailsPage />} />
                </Routes>
            </div>
            <Footer/>
        </Router>
    </div>
  );
}

export default App;
