import React from 'react';
import CardsGrid from "../components/CardsGrid";
import ScrollAnimatedDiv from "../components/ScrollAnimatedDiv"

const Home = () => {
    return (
        <div className='home'>

            <section className="hero-section">
<ScrollAnimatedDiv/>

            </section>
            <h3>
                 –
            </h3>
            <CardsGrid/>
        </div>
    );
};

export default Home;
