import React from 'react';
import Dropdown from "./Dropdown";
const InfoSection = ({ title, description, credits, images }) => {
    return (
        <section className="info-section">
            <div className="info-container">
                {/* Colonne gauche : Titre */}
                <div className="info-title">
                    <h1>{title}</h1>
                </div>
                {/* Colonne droite : Texte et Dropdown */}
                <div className="info-text">
                    <p>{description}</p>

                    {credits && (
                        <Dropdown label="& More Information" items={credits} />
                    )}
                </div>
            </div>

            {/* Section des images */}
            {images && images.length > 0 && (
                <div className="info-images">
                    {images.map((image, index) => (
                        <div
                            key={index}
                            className={`image-item ${image.fullWidth ? 'full-width' : 'half-width'}`}
                        >
                            <img
                                src={image.src}
                                alt={image.alt || `Image ${index + 1}`}
                                className="info-image"
                            />
                        </div>
                    ))}
                </div>
            )}
        </section>
    );
};

export default InfoSection;
